.streamsContainer{
    max-height: 100%;;
    flex: 1;
    margin-top: 10px;
    overflow-y: auto;
}

.streamRowHeaderContainer{
    display: flex;
    align-items: center;
    width: 45px;
}

.streamRowHeader{
    margin-bottom: 0px;
}

.streamRow{
    display: flex;
    flex-wrap: wrap;
}

.levelProgressInput{
	width: 15px;
}

.levelProgressInput::-webkit-inner-spin-button, 
.levelProgressInput::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.finishedTime{
	width: 55px;
}

.streamControls{
    justify-content: space-between;
    padding: 0px 5px;
}

.streamContainer{
    margin-top: 10px;
	margin-right: 20px;
}