.loginContainer {
    box-sizing: border-box;
    margin: auto;
    width: 400px;
    height: 300px;
    border-radius: 10px;
    border: 2px solid white;
    text-align: center;
}

.raceTypeSelectContainer {
    padding-top: 35px;
    box-sizing: border-box;
    width: 400px;
    height: 300px;
    border-radius: 10px;
    border: 2px solid white;
}

.raceTypeSelectContainer h2{
    text-align: center;
}

.raceTypeSelection {
    box-sizing: border-box;
    height: 30px;
    width: 90%;
    border: 1px solid white;
    border-radius: 10px;
    background: white;
    color: black;
    font-size: 1.5em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    cursor: pointer;
}