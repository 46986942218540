.racerList{
	width: 210px;
}

.runner{
	min-width: max-content;
	height: 23px;
	line-height: 23px;
	padding-left: 3px;
}

.isDNF{
	background-color: #ff000085;
}

.isFinished{
	background-color: #00800082;
}