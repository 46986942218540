.commentaryHeader{
    border-bottom: 2px solid white;
}

.commentaryContainer{
    height: 100%;
    width: 100%;
}

.commentaryViewSelect{
    box-sizing: border-box;

    border: 1px solid white;
    margin-bottom: -2px;
    padding: 10px;
    cursor: pointer;
}

.commentaryViewSelect:first-of-type{
    border-left: 2px solid white;
    border-top-left-radius: 5px;
}

.commentaryViewSelect:last-of-type{
    border-right: 2px solid white;
    border-top-right-radius: 5px;
}

.selectedCommentaryView{
    border-bottom: 2px solid #333;
}

.raceView, .racersContainer{
    height: 100%;
}

.userList{
    padding: 10px;
    margin-top: 10px;
    border: 2px solid white;
    border-radius: 10px;
    width: 200px;
}

.userList h3 {
    text-align: center;
}

.podium {
    display: flex;
    justify-content: space-around;
    padding: 10px;
}

.podiumSpot{
    box-sizing: border-box;
    border: 2px solid white;
    border-radius: 10px;
    height: 100px;
    width: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
}

.podiumSpot h3{
    margin: 0px;
}

.commentaryContainer .racerList{
    box-sizing: border-box;
    height: 100%;
	width: 210px;
    padding-top: 10px;
}

.racerStats{
    width: 100%;
    height: 100%;
    border-left: 2px solid white;
    border-top: 2px solid white;
    padding: 10px;
}

.racerStats h2{
    margin-left: 30px;
}

.twitchLinkLogo{
    width: 20px;
}

.runner{
	min-width: max-content;
	height: 23px;
	line-height: 23px;
	padding-left: 3px;
}

.isDNF{
	background-color: #ff000085;
}

.isFinished{
	background-color: #00800082;
}

.statCardContainer{
    display: flex;
    flex-wrap: wrap;
}

.statCard {
    border: 2px solid white;
    padding: 10px;
    margin-right: 10px;
}

.statCard h3{
    text-align: center;
}

.podium {
    border-collapse: collapse;
}

.podium td{
    border: 1px solid white;
    padding: 3px 5px;
}

.creatorPastLevels td {
    border: 1px solid white;
    border-collapse: collapse;
    padding: 3px 5px;
}