html{
	width: 100%;
	height: 100%;
}

body{
	background-color: #333;
	color: white;
	width: 100%;
	height: 100%;
	margin: 0;
}

a, a:visited{
	color: white;
}

#app{
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
}

.column {
	display: flex;
	flex-direction: column;
}

.row {
	display: flex;
	flex-direction: row;
}